export const sanityConfig = {
  // Find your project ID and dataset in `sanity.json` in your studio project
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  useCdn: false,
  token: process.env.REACT_APP_SANITY_TOKEN,
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
  apiVersion: '2021-03-25',
  // see https://www.sanity.io/docs/api-versioning for how versioning works
}
