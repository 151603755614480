import * as R from 'ramda'
import qs from 'qs'
import axios from 'axios'
import Calendar from 'react-calendar'
import creditCardType from 'credit-card-type'
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import withReactContent from 'sweetalert2-react-content'

import {
  time,
  date,
  notNilOrEmpty,
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC,
  nilOrEmpty,
  mapIndexed
} from '../lib/helpers'
import {
  FormatCCType,
  FormDataType,
  FormDataCCType,
  PaymentResponseType
} from '../lib/types'

import { sanityClient } from '../lib/sanity'

import 'react-calendar/dist/Calendar.css'
import CreditCardForm from './CreditCard/CreditCard'

const ApptModal = withReactContent(Swal)

type PropsType = {
  aptDate: any
  appointments: any
  setMessage: any
  member_number: number
}

type MemberType = {
  _id: string
  email: string
}

const AppointmentsCalendar = ({
  aptDate,
  appointments,
  setMessage,
  member_number
}: PropsType) => {
  const [appointment_date_time, setAppointmentDateTime] = React.useState('')
  const [member, setMember] = React.useState<MemberType>({ _id: '', email: '' })

  const workableHours = [
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM'
  ]

  React.useEffect(() => {
    setAppointmentDateTime(aptDate)
  }, [aptDate])

  React.useEffect(() => {
    notNilOrEmpty(member_number) &&
      sanityClient
        .fetch(`*[_type == 'user' && member_number == ${member_number}][0]`)
        .then(({ _id, email }) => {
          notNilOrEmpty(_id)
            ? setMember({ _id, email })
            : console.log('user not found')
        })
        .catch((err) => console.error)
  }, [member_number])

  return (
    <div>
      <Calendar
        minDate={new Date()}
        tileDisabled={({ date }) => date.getDay() === 0 || date.getDay() === 6}
        calendarType="US"
        onClickDay={async (value: any) => {
          const currentAppt = R.filter(
            R.propEq('date', date(value)),
            appointments
          )
          const notAvailableHours = currentAppt.map((time: any) => time.time)

          const availableHours = (n: any) => !notAvailableHours.includes(n)
          const displayHours = R.filter(availableHours, workableHours)

          const { value: appointment } = await Swal.fire({
            title: 'Select an available time slot',
            input: 'select',
            inputOptions: displayHours,
            inputPlaceholder: 'Select a Time',
            showCancelButton: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                  resolve('')
                } else {
                  resolve('You need to select a time :)')
                }
              })
            }
          })

          if (appointment) {
            ApptModal.fire({
              html: (
                // member_number is their number in rap
                // memberId is the id in Sanity for that user
                <PaymentForm
                  appointmentDate={date(value)}
                  appointmentTime={displayHours[appointment]}
                  member_email={member.email}
                  member_number={member_number}
                  memberId={member._id}
                  amount={50}
                />
              ),
              showConfirmButton: false
            })
          }
        }}
      />
    </div>
  )
}

const PaymentForm = ({
  appointmentDate,
  appointmentTime,
  member_email,
  member_number,
  memberId,
  amount
}: any) => {
  const [formData, setFormData] = useState<FormDataCCType>({
    cc: null,
    name: '',
    expiration: '',
    cvc: null,
    cc_type: '',
    member_number: member_number,
    amount: amount,
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: null
  })
  const [paymentResult, setPaymentResult] = useState<any>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [focus, setFocus] = useState('')
  const [ccType, setCCType] = useState<FormatCCType>({
    code: { name: '', size: null },
    gaps: [],
    lengths: [],
    matchStrength: null,
    niceType: '',
    patterns: [],
    type: ''
  })

  function _handleInputFocus({ target }: React.ChangeEvent<HTMLInputElement>) {
    setFocus(target.name)
  }

  function _handleInputCCChange(e: React.ChangeEvent<HTMLInputElement>) {
    let cardType: any
    let cardNumber: any

    if (e.target.name === 'cc' && R.gte(e.target.value.length, 2))
      cardType = creditCardType(e.target.value)[0]

    setCCType(cardType)

    cardNumber = formatCreditCardNumber(e.target.value)

    e.target.value = cardNumber

    setFormData({
      ...formData,
      cc: e.target.value
    })
  }

  function _handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    // let target = e.target as HTMLInputElement
    if (e.target.name === 'expiration') {
      e.target.value = formatExpirationDate(e.target.value)
    } else if (e.target.name === 'cvc') {
      e.target.value = formatCVC(e.target.value, {}, ccType.type)
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  function _onSubmit(e: any) {
    e.preventDefault()

    setLoading(true)

    var data = qs.stringify({
      memberId: memberId,
      member_number: Number(member_number),
      member_email: member_email,
      name: formData.name,
      expiration: Number(R.replace(/\//g, '', formData.expiration)),
      cc: Number(R.replace(/\s+/g, '', formData.cc)),
      amount: formData.amount,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      zipcode: formData.zipcode,
      cc_type: ccType.niceType
    })

    axios({
      method: 'post',
      url: 'https://apis.lvrealtors.com/api/nar_process_payment',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    })
      .then(function ({ data }: PaymentResponseType) {
        setPaymentResult({ ...data.body, statusCode: data.statusCode })
      })
      .catch(function (error: any) {
        console.log(error)
      })

    setLoading(false)
  }

  return isLoading ? (
    <>Processing...</>
  ) : nilOrEmpty(paymentResult) ? (
    <>
      <h2>
        {appointmentDate} @ {appointmentTime}
      </h2>
      <CreditCardForm
        ccType={ccType}
        focus={focus}
        formData={formData}
        onInputChange={_handleInputChange}
        onCCInputChange={_handleInputCCChange}
        onFocus={_handleInputFocus}
        onSubmit={_onSubmit}
        billingInfo={true}
      />
    </>
  ) : (
    <div>
      {R.equals(paymentResult.statusCode, 0) ? (
        Swal.fire({
          confirmButtonText: 'Close',
          icon: 'success',
          title: paymentResult.ResultMessage,
          text: 'You will receive an email with the confirmation details',
          showCancelButton: false,
          preConfirm: (values) => {
            fetch('https://apis.lvrealtors.com/api/sendEmail', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              body: qs.stringify({
                emailTo: member_email,
                emailBody: (`
                  You have an headshot Appointment at LVR, on ${appointmentDate} at  ${appointmentTime}
                `),
                title: 'Headshots Appointment at LVR',
                subject: 'Headshots Appointment at LVR',
                staff_member_number: 526
              })
            })
              .then((response) =>
                fetch('https://apis.lvrealtors.com/api/createAppointment', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  body: qs.stringify({
                    member_number: member_number,
                    date: appointmentDate,
                    time: appointmentTime,
                    staff_member_number: 526
                  })
                })
                  .then((response) => console.log('success'))
                  .catch((err) => console.error)
              )
              .catch((err) => console.error)
          }
        })
      ) : (
        <>
          <div
            className="swal2-icon swal2-error swal2-icon-show"
            style={{ display: 'flex' }}
          >
            <span className="swal2-x-mark">
              <span className="swal2-x-mark-line-left"></span>
              <span className="swal2-x-mark-line-right"></span>
            </span>
          </div>
          <h3 style={{ paddingTop: 10 }}>{paymentResult.ResultMessage}</h3>
          <Button variant="contained" onClick={() => setPaymentResult(null)}>
            Try Again
          </Button>
        </>
      )}
    </div>
  )
}

export default AppointmentsCalendar
