import * as R from 'ramda'
import qs from 'qs'
import axios from 'axios'
import { useEffect, useState } from 'react'
import AppointmentsCalendar from './componets/Calendar'
import { sanityClient } from './lib/sanity'
import './App.scss'
import { time, date, nilOrEmpty, notNilOrEmpty } from './lib/helpers'

function App(props: any) {
  const [message, setMessage] = useState<string>('')
  const [appointments, setAppointments] = useState<any>([])
  const [member_number, setMemberNumber] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [requestingMember, setRequestingMember] = useState<any>({
    member_number: '',
    first_name: '',
    last_name: ''
  })
  const staff_member_number: number = 526

  const params = R.slice(14, Infinity, R.tail(window.location.search))

  useEffect(() => {
    window.location.search && setMemberNumber(params)
  }, [])

  useEffect(() => {
    if (member_number !== '') fetchData()
  }, [member_number])

  async function fetchData(e?: any) {
    e?.preventDefault()
    const sanityData =
      await sanityClient.fetch(`*[_type == "appointments" && staff_member->member_number == ${staff_member_number}] {
      date,
      title,
      requesting_member->{ member_number, first_name, last_name },
      staff_member->{ member_number, email }
    }`)
    const sanityMemberData =
      await sanityClient.fetch(`*[_type == 'user' && member_number == ${member_number}][0] {
        member_number,
        first_name,
        last_name,
      }`)

    if (notNilOrEmpty(member_number)) {
      if (notNilOrEmpty(sanityMemberData)) {
        setRequestingMember({
          member_number: sanityMemberData.member_number,
          first_name: sanityMemberData.first_name,
          last_name: sanityMemberData.last_name
        })
      } else {
        _fetchRapData()
      }

      setAppointments(
        sanityData.map((item: any) => {
          return {
            date: date(item.date),
            member: `${item.requesting_member.first_name} ${item.requesting_member.last_name}`,
            time: time(item.date)
          }
        })
      )
    }
  }

  async function _fetchRapData() {
    setIsLoading(true)
    const rap = await fetch(
      `https://rap-data.lvrealtors.com/getMemberByNumber`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          member_number: member_number
        })
      }
    )
    const { statusCode, body: userData } = await rap.json()

    // const userExists = await sanityClient.fetch(`*[_type == "user" && member_number == ${member_number} ][0]`)

    async function createReadStream(user_id: number, file_url: string) {
      let data
      const response = await axios('https://apis.lvrealtors.com/getAgentPic', {
        method: 'post',
        data: qs.stringify({
          file_url: file_url,
          user_id: user_id
        })
      })
    }

    if (R.equals(statusCode, 200)) {
      await sanityClient
        .create({
          _type: 'user',
          active: true,
          email: userData[0].email_address,
          password: userData[0].pwd,
          first_name: userData[0].first_name,
          home_phone_area: userData[0].home_phone_area,
          home_phone_number: userData[0].home_phone_number,
          last_name: userData[0].last_name,
          middle_name: userData[0].middle_name_initial,
          member_number: userData[0].member_number,
          mls_id: userData[0].MLS_Id
        })
        .then((res: any) => {
          createReadStream(res._id, userData[0].member_pic_file)
        })
    }

    setIsLoading(false)
    fetchData()
  }

  return (
    <div className="App">
      <header className="App-header">
        {notNilOrEmpty(member_number) ? (
          <>
            <h1>
              {isLoading
                ? 'Loading...'
                : `Hello, ${requestingMember.first_name}`}
            </h1>
            <AppointmentsCalendar
              appointments={appointments}
              setMessage={setMessage}
              aptDate={message}
              member_number={member_number}
            />
            {notNilOrEmpty(message) && <p>Chosen Date: {message}</p>}
          </>
        ) : (
          <h1>Missing Member Number</h1>
        )}
      </header>
    </div>
  )
}

export default App
