/**
 * Server-side Sanity utilities. By having these in a separate file from the
 * utilities we use on the client side, we are able to tree-shake (remove)
 * code that is not used on the client side.
 */
import client from '@sanity/client'

import { sanityConfig } from './config'
// import { getAllPostsQuery, getMainMenuQuery } from './queries'

export const sanityClient = client(sanityConfig)

// export async function getAllPosts() {
//     const data = await sanityClient().fetch(getAllPostsQuery)
//     return data
// }

// export async function getMainMenu(preview) {
//   const data = await getClient().fetch(getMainMenuQuery)
//   return data
// }
