import * as R from 'ramda'
import creditCardType from 'credit-card-type'

export const notEmpty = R.complement(R.isEmpty)

export const notNil = R.complement(R.isNil)

export const notNilOrEmpty = R.complement(R.either(R.isNil, R.isEmpty))

export const notEquals = R.curry((a, b) => R.complement(R.equals(a))(b))

export const nilOrEmpty = R.either(R.isNil, R.isEmpty)

export const mapIndexed = R.addIndex(R.map)

export const getYoutubeUrlId = R.cond([
  [R.startsWith('https://youtu'), (val) => R.slice(-11, Infinity, val)],
  [
    R.startsWith('https://www.youtube'),
    (val) => R.compose(R.head, (e) => R.split('&', e[1]), R.split('='))(val)
  ],
  [R.T, (val) => val]
])

export const isOdd = R.modulo(R.__, 2)

export function randomRangeNum(min, max) {
  return Math.random() * (max - min) + min
}

export function rangeFloor(min, max) {
  // Return a random whole number between min and max
  return Math.floor(Math.random() * (max - min) + min)
}

export function pick(array) {
  // Pick a random item out of an array
  if (array.length === 0) return undefined
  return array[rangeFloor(0, array.length)]
}

export const time = (t) => new Date(t).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
export const date = (d) => new Date(d).toLocaleDateString()

export function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatCVC(value, allValues = {}, cardIssuer) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  maxLength = cardIssuer === "american-express" ? 4 : 3;

  return clearValue.slice(0, maxLength);
}

export function formatCreditCardNumber(cardValue) {
  if (!cardValue) {
    return cardValue;
  }

  const ccInfo = creditCardType(cardValue)[0]
  const { type: issuer } = notNilOrEmpty(ccInfo) && ccInfo
  const clearValue = clearNumber(cardValue);
  let nextValue;

  switch (issuer) {
    case "american-express":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "diners-club":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();

}
