import * as R from 'ramda'
import { Button, Divider, TextField, Typography } from '@mui/material'
import { nilOrEmpty, notNilOrEmpty } from '../../lib/helpers'
import Card from './Card'
import type {CreditCardProps} from '../../lib/types'

function CreditCardForm({
  billingInfo,
  onFocus,
  focus,
  onInputChange,
  onCCInputChange,
  onSubmit,
  formData,
  ccType
}: CreditCardProps) {
  return (
    <>
      <Card
        formData={formData}
        issuer={notNilOrEmpty(ccType) ? ccType.type : 'visa'}
        focused={focus}
      />
      <Typography variant="h4">${formData.amount}</Typography>
      <form
        style={{ paddingBottom: 16, display: 'flex', flexFlow: 'column' }}
        onSubmit={onSubmit}
      >
        <TextField
          label="Cardholder Name"
          name="name"
          onChange={onInputChange}
          onFocus={onFocus}
          placeholder="Enter cardholder's full name"
          required
          sx={{ my: 2 }}
          value={formData.name}
        />
        <TextField
          color="secondary"
          label="Credit Card Number"
          name="cc"
          onChange={onCCInputChange}
          onFocus={onFocus}
          placeholder="•••• •••• •••• ••••"
          required
          sx={{ my: 2 }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <TextField
            className="swal2-input"
            label="Expiration date"
            name="expiration"
            onChange={onInputChange}
            onFocus={onFocus}
            placeholder="MMYY"
            required
            sx={{ my: 2 }}
            type="tel"
            value={formData.expiration || ''}
          />
          <TextField
            className="swal2-input"
            disabled={nilOrEmpty(formData.cc)}
            label="CVC/CVV"
            name="cvc"
            onChange={onInputChange}
            onFocus={onFocus}
            placeholder="•••"
            required
            sx={{ my: 2 }}
            type="tel"
          />
        </div>
        <Divider />
        {billingInfo && (
          <>
            <TextField
              label="Addres Line 1"
              name="address1"
              onChange={onInputChange}
              onFocus={onFocus}
              placeholder="Address Line 1"
              required
              sx={{ my: 2 }}
              type="text"
            />
            <TextField
              label="Addres Line 2"
              name="address2"
              onChange={onInputChange}
              onFocus={onFocus}
              placeholder="Address Line 2"
              sx={{ my: 2 }}
              type="text"
            />
            <TextField
              label="City"
              name="city"
              onChange={onInputChange}
              onFocus={onFocus}
              placeholder="City"
              required
              sx={{ my: 2 }}
              type="text"
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                label="State"
                name="state"
                onChange={onInputChange}
                onFocus={onFocus}
                placeholder="State"
                required
                sx={{ my: 2 }}
                type="text"
              />
              <TextField
                label="Zipcode"
                name="zipcode"
                onChange={onInputChange}
                onFocus={onFocus}
                placeholder="Zipcode"
                required
                sx={{ my: 2 }}
                type="tel"
              />
            </div>
          </>
        )}
        <Button
          variant="contained"
          className="swal2-confirm swal2-styled"
          type="submit"
          sx={{ my: 2 }}
        >
          Pay
        </Button>
      </form>
    </>
  )
}

export default CreditCardForm
