import { notNilOrEmpty } from '../../lib/helpers'
import './styles.scss'

const Card = ({ formData: {cc, cvc, expiration, name }, issuer, focused }: any) => {
  return (
    <div key="Cards" className="rccs">
      <div
        className={[
          'rccs__card',
          `rccs__card--${issuer || ''}`,
          focused === 'cvc' && issuer !== 'american-express'
            ? 'rccs__card--flipped'
            : ''
        ]
          .join(' ')
          .trim()}
      >
        <div className="rccs__card--front">
          <div className="rccs__card__background" />
          <div className="rccs__issuer" />
          <div
            className={[
              'rccs__cvc__front',
              focused === 'cvc' ? 'rccs--focused' : ''
            ]
              .join(' ')
              .trim()}
          >
            {cvc}
          </div>
          <div
            className={[
              'rccs__number',
              // notNilOrEmpty(cc) && cc.replace(/ /g, '').length > 16 ? 'rccs__number--large' : '',
              focused === 'cc' ? 'rccs--focused' : ''
              // notNilOrEmpty(cc) && cc.substr(0, 1) !== '•' ? 'rccs--filled' : ''
            ]
              .join(' ')
              .trim()}
          >
            {cc}
          </div>
          <div
            className={[
              'rccs__name',
              focused === 'name' ? 'rccs--focused' : '',
              cc ? 'rccs--filled' : ''
            ]
              .join(' ')
              .trim()}
          >
            {name || 'YOUR NAME HERE'}
          </div>
          <div
            className={[
              'rccs__expiry',
              focused === 'expiration' ? 'rccs--focused' : '',
              'rccs--filled'
            ]
              .join(' ')
              .trim()}
          >
            <div className="rccs__expiry__valid">Good Thru</div>
            <div className="rccs__expiry__value">{expiration}</div>
          </div>
          <div className="rccs__chip" />
        </div>
        <div className="rccs__card--back">
          <div className="rccs__card__background" />
          <div className="rccs__stripe" />
          <div className="rccs__signature" />
          <div
            className={['rccs__cvc', focused === 'cvc' ? 'rccs--focused' : '']
              .join(' ')
              .trim()}
          >
            {cvc}
          </div>
          <div className="rccs__issuer" />
        </div>
      </div>
    </div>
  )
}

export default Card
